import { AddressIcon, LogoMainNSTGateway, PhoneCallingIcon } from "assests/Home";
import { nstGatewayHeading, nstSocialMediaRef } from "content/Footer";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import useScrollToTop from "utils/useScrollToTop";
import "./Footer.css";
import { CONSTANT_ROUTES } from "constants/ConstantRoutes";
import { useEffect } from "react";

const Footer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const addressMapLink = "https://maps.app.goo.gl/T6z4Xj3e7dyhjJ1E6";
    const { handleLogoClick } = useScrollToTop();

    const handleSubSectionClick = (href: string, panelId: string) => {
        navigate(href, {state: { panelId }})
    };
    useEffect(() => {
        if (location.state?.panelId) {
            const section = document.getElementById(location.state.panelId);
            if (section) {
                const rect = section.getBoundingClientRect();
                const scrollPosition = window.scrollY + rect.top - 150;
                window.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth',
                });
            }
        }
    }, [location]);
    return (
        <footer>
            <div className="nst-gateway-get-in-touch-main-container">
                <div className="container">
                    <div className="nst-gateway-footer-wrap">
                        <div className="nst-footer-top">
                            <div className="nst-gateway-contact-us-details-content-section-left">
                            <div className="nst-gateway-content-upper-section">
                                    <div className="nst-gateway-ready-to-accept-payment-heading-content">
                                        <h4>Ready to accept payments anytime, anywhere?</h4>
                                    </div>
                                    <div className="nst-gateway-getstarted-hyperlink">
                                        <div className="navigate-use-btn">
                                            <Link className="get-started-btn " to={CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.USER.SIGNUP}>
                                                Get Started
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="nst-gateway-logo-main">
                                    <Link to="/" onClick={handleLogoClick}>
                                        <LogoMainNSTGateway />
                                    </Link>
                                </div>
                                {/* <div className="nst-gateway-on-social-media">
                                    {nstSocialMediaRef ? (
                                        nstSocialMediaRef.map((media, index) => (
                                            <NavLink key={media.key} to={media.link}>
                                                {media.mediaIcon}
                                            </NavLink>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </div> */}
                                {/* <div className="nst-gateway-contact-us-add-call-sec">
                                    <div className="nst-gateway-contact-us-address">
                                        <div className="nst-gateway-contact-us-address-badge-icon">
                                            <AddressIcon />
                                        </div>
                                        <div className="nst-gateway-contact-us-our-address">
                                            <Link style={{ color: "white" }} target="_blank" to={addressMapLink}>
                                                1968 S. Coast Hwy #2851 Laguna Beach, CA 92651
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="nst-gateway-contact-us-phone-calling">
                                        <div className="nst-gateway-contact-us-phone-calling-badge">
                                            <PhoneCallingIcon />
                                        </div>
                                        <div className="nst-gateway-contact-us-call-us-on">
                                            <a href="tel:+18006832977" className="phone-number-link">
                                                (800) 683-2977
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="nst-gateway-contact-us-details-content-section-right">
                                {/* <div className="nst-gateway-content-upper-section">
                                    <div className="nst-gateway-ready-to-accept-payment-heading-content">
                                        <h4>Ready to accept payments anytime, anywhere?</h4>
                                    </div>
                                    <div className="nst-gateway-getstarted-hyperlink">
                                        <div className="navigate-use-btn">
                                            <Link className="get-started-btn " to="/signup">
                                                Get Started
                                            </Link>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="nst-gateway-content-below-support-link-section">
                                    <div className="columns-container">
                                        {nstGatewayHeading.map((section, index) => (
                                            <div key={section.heading} className="column">
                                                <h4 className={section.className}>{section.heading}</h4>
                                                <ul>
                                                    {section.subSections.map((subSection, i) => (
                                                        <li key={subSection.nameLink} aria-hidden={true} onClick={() => handleSubSectionClick(subSection?.href, subSection?.key)}>
                                                            {index === 0 ? (
                                                                <NavLink key={subSection.href} to={subSection.href}>
                                                                    {subSection?.nameLink}
                                                                </NavLink>
                                                            ) : (
                                                                <Link key={subSection.href} to={subSection.href}>
                                                                    {subSection?.nameLink}
                                                                </Link>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="nst-gateway-contact-us-add-call-sec">
                                    <div className="nst-gateway-contact-us-address">
                                        <div className="nst-gateway-contact-us-address-badge-icon">
                                            <AddressIcon />
                                        </div>
                                        <div className="nst-gateway-contact-us-our-address">
                                                <Link style={{ color: "white" }} target="_blank" to={addressMapLink}>
                                                    1503 Macdonald Ave, Ste 204A, Richmond, CA 94801
                                                </Link>
                                        </div>
                                    </div>
                                    <div className="nst-gateway-contact-us-phone-calling">
                                        <div className="nst-gateway-contact-us-phone-calling-badge">
                                            <PhoneCallingIcon />
                                        </div>
                                        <div className="nst-gateway-contact-us-call-us-on">
                                            <a href="tel:+18006832977" className="phone-number-link">
                                                (800) 683-2977
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="nst-footer-bottom">
                            <div className="nst-gateway-copy-right-notice-content">
                                <div className="copyright-notice-org-text">
                                    <span>© 2024 NST Gateway Payments. All Rights Reserved.</span>
                                </div>
                                <div className="nst-gateway-on-social-media">
                                    {nstSocialMediaRef ? (
                                        nstSocialMediaRef.map((media, index) => (
                                            <NavLink key={media.key} to={media.link}>
                                                {media.mediaIcon}
                                            </NavLink>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
